<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>loyalty</md-icon>
          </div>
          <h4 class="title">Subscription Code : <b>{{ code }}</b></h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="User Agent">{{ item.user_agent }}</md-table-cell>
              <md-table-cell md-label="IP Address">
                {{ item.ip_address }} <br> {{ item.ip_address_details }}
                </md-table-cell>
              <md-table-cell md-label="Time">{{ item.login_time }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: flex;">
                  <md-button class="md-just-icon md-danger" @click="UpdateLogoutFlag(item._id)"><md-icon>logout</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import SubscriptionCodeService from "../../services/SubscriptionCodeService";

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      isDisabled: false,
      code: this.$route.query.code,
    };
  },
  methods: {
    GetLoginHistory() {
        let data= {
            code: this.code
        }
      SubscriptionCodeService.GetLoginHistory(data)
        .then((response) => {
            this.tableData = response.data.data;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    UpdateLogoutFlag(id) {
        let data= {
            id: id
        }
      SubscriptionCodeService.UpdateLogoutFlag(data)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetLoginHistory();
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetLoginHistory()
  },
};
</script>

import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

//Login User Profile Routes
import ChangePassword from "@/pages/Auth/ChangePassword.vue";
import UpdateProfile from "@/pages/Auth/UpdateProfile.vue";

// Forget Password
import ForgetPassword from "@/pages/Dashboard/Pages/ForgetPassword.vue";
import ResetPassword from "@/pages/Dashboard/Pages/ResetPassword.vue";

// Privacy & Security
import PrivacySecurity from "@/pages/Auth/PrivacySecurity.vue";
import VerifyOtp from "@/pages/Dashboard/Pages/VerifyOtp.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Content Routes
import CreateContent from "@/pages/Content/Create.vue";
import ContentList from "@/pages/Content/List.vue";
import QuestionList from "@/pages/Content/QuestionList.vue";
import QuestionAdd from "@/pages/Content/QuestionAdd.vue";

//Subscription Code Routes
import ListSubscriptionCode from "@/pages/SubscriptionCode/List.vue";
import SubmitContentList from "@/pages/SubscriptionCode/SubmitContentList.vue";
import ViewResult from "@/pages/SubscriptionCode/ViewResult.vue";
import ListSubscriptionCodeLoginHistory from "@/pages/SubscriptionCode/LoginHistory.vue";
import SubscriptionCodePaymentHistory from "@/pages/SubscriptionCode/PaymentHistory.vue";

//Appointments Code Routes
import AppointmentsList from "@/pages/Appointments/List.vue";

//Child App Routes
import ChildHomePage from "@/pages/Children/ChildHomePage.vue";
import ChildLoginPage from "@/pages/Children/ChildLoginPage.vue";
import ChildContentsPage from "@/pages/Children/ChildContentsPage.vue";
import ChildContentDetailPage from "@/pages/Children/ChildContentDetailPage.vue";
import ChildPlanList from "@/pages/Payments/PlanList.vue";
import ChildPaymentPage from "@/pages/Payments/PaymentPage.vue";

//Parents App Routes
import ParentHomePage from "@/pages/Parent/ParentHomePage.vue";
import ParentContentsPage from "@/pages/Parent/ParentContentsPage.vue";
import ParentContentDetailPage from "@/pages/Parent/ParentContentDetailPage.vue";
import ParentAppointment from "@/pages/Parent/ParentAppointment.vue";
import ParentPaymentHistory from "@/pages/Parent/ParentPaymentHistory.vue";
import ParentPaymentPage from "@/pages/Parent/ParentPaymentPage.vue";

import auth from '../middleware/auth';
import beforeAuth from '../middleware/beforeAuth';
import childAuth from '../middleware/childAuth';
import beforeChildAuth from '../middleware/beforeChildAuth';

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      meta: {
        middleware: [auth],
      },
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
      },
      components: { default: RtlSupport },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/verify-otp",
      name: "VerifyOtp",
      component: VerifyOtp,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/forget-password",
      name: "ForgetPassword",
      component: ForgetPassword,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

let contentModules = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/content",
      name: "Content List",
      component: ContentList,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/create-content",
      name: "Create Content",
      component: CreateContent,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/question-list",
      name: "Question List",
      component: QuestionList,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/question-add",
      name: "Question Add",
      component: QuestionAdd,
      meta: {
        middleware: [auth],
      },
    },
  ]
};

let subscriptionCodeModules = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/subscription-code",
      name: "Subscription Code List",
      component: ListSubscriptionCode,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/submit-content-list",
      name: "Submitted Content List",
      component: SubmitContentList,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/view-result",
      name: "View Content Result",
      component: ViewResult,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/login-history",
      name: "Login History",
      component: ListSubscriptionCodeLoginHistory,
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/payment-history",
      name: "Payment History",
      component: SubscriptionCodePaymentHistory,
      meta: {
        middleware: [auth],
      },
    },
  ]
};

let appointmentModules = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "/appointments",
      name: "Appointments",
      component: AppointmentsList,
      meta: {
        middleware: [auth],
      },
    },
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      middleware: [beforeAuth],
    },
  },
  {
    path: "/super-admin",
    redirect: "super-admin/login",
    meta: {
      middleware: [beforeAuth],
    },
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  contentModules,
  subscriptionCodeModules,
  appointmentModules,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        components: { default: ChangePassword },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "privacy-security",
        name: "PrivacySecurity",
        components: { default: PrivacySecurity },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "update-profile",
        name: "UpdateProfile",
        components: { default: UpdateProfile },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
    ],
  },

  // Parent Routes
  {
    path: "/parent",
    name: "Parents Home",
    component: ParentHomePage,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/parent/contents",
    name: "Parent Contents",
    component: ParentContentsPage,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/parent/content",
    name: "Parent Content Detail",
    component: ParentContentDetailPage,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/parent/appointment",
    name: "Appointment",
    component: ParentAppointment,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/parent/payments-history",
    name: "Payment History",
    component: ParentPaymentHistory,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/parent/make-payment",
    name: "Payment Page",
    component: ParentPaymentPage,
    meta: {
      middleware: [childAuth],
    },
  },

  // Child Routes
  {
    path: "/child",
    name: "child",
    component: ChildLoginPage, //ChildHomePage,
    meta: {
      middleware: [beforeChildAuth],
    },
  },
  {
    path: "/child/login",
    name: "Child Login",
    component: ChildLoginPage,
    meta: {
      middleware: [beforeChildAuth],
    },
  },
  {
    path: "/child/contents",
    name: "Child Contents",
    component: ChildContentsPage,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/child/content",
    name: "Child Content Detail",
    component: ChildContentDetailPage,
    meta: {
      middleware: [childAuth],
    },
  },
  {
    path: "/child/plans",
    name: "Plan List",
    component: ChildPlanList,
    meta: {
      middleware: [beforeChildAuth],
    },
  },
  {
    path: "/child/payment",
    name: "Make Payment",
    component: ChildPaymentPage,
    meta: {
      middleware: [beforeChildAuth],
    },
  },
];

export default routes;

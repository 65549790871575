<template>
    <div class="container-fluid mobile-container-wrapper">
        <div class="fading">
            <h3><b>Payment Details</b></h3>
            <!--<h3 class="animate-charcter"><b>Purchase a plan</b></h3>-->
        </div>
        <div class="mt-3">
            <div class="md-layout mobile-form-wrapper">
                <div class="md-layout-item md-size-100" v-if="hasError">
                    <div class="error">{{ hasError }}</div>
                </div>
                <div class="md-layout-item md-size-100">
                    <md-field class="fading">
                        <md-input class="border-box" v-model="email" placeholder="Enter email" type="text"></md-input>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-100">
                    <md-field class="fading">
                        <md-input class="border-box" v-model="childName" placeholder="Enter child name" type="text"></md-input>
                    </md-field>
                </div>
                <!--<div class="md-layout-item md-size-100">
                  <md-field>
                    <select class="border-box" :style="{ width: '100%' }" placeholder="Select Category" v-model="category">
                        <option value="">Select Category</option>
                        <option v-for="category in GetCategory()" :key="category" :value="category">{{ category }}</option>
                    </select>
                  </md-field>
                </div>-->
                <div class="md-layout-item md-size-100">
                    <div class="option-block-container">
                        <div class="form-check option-block fading-1" v-for="plan in plans" :key="plan.id">
                            <span :style="{ 'color': '#ed1651' }" v-if="plan.interval == 'year'">Yearly</span>
                            <span :style="{ 'color': '#ed1651' }"  v-else-if="plan.interval == 'month'">Monthly</span>
                            <span :style="{ 'color': '#ed1651' }" v-else>{{ plan.interval }}</span><br>
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="planId" v-model="planId" :value="plan.id" />
                                <span class="font-weight-bold">€{{ plan.amount / 100 }} / {{ plan.interval }}</span><br>
                                <!--<p>{{ plan.nickname }}</p>-->
                            </label>
                        </div>
                    </div>
                </div>
                <div class="md-layout-item md-size-100">
                    <div ref="card"></div>
                </div>
                <div class="form-check md-layout-item md-size-100">
                    <br>
                    <input class="form-check-input" type="checkbox" v-model="acceptTermsCheckBox" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Accept Terms & Condition
                    </label>
                </div>
                <div class="md-layout-item md-size-100">
                    <br>
                    <vue-recaptcha
                        :sitekey="siteKey"
                        @verify="verifyCaptchaMethod"
                        >
                    </vue-recaptcha>
                </div>
                <div class="md-layout-item md-size-100 fading">
                    <br>
                    <button class="btn btn-warning btn-lg btn-block" :disabled="isDisabled" @click="subscribePlan()">Purchase <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                    <br>
                    <p><b>Already a member? </b></p>
                    <button class="btn btn-primary btn-lg btn-block" @click="$router.push({ path: '/child'})">Login</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PaymentService from "../../services/PaymentService";
import { VueRecaptcha } from 'vue-recaptcha';
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
    elements = stripe.elements(),
    card = undefined;
/*const paymentRequest = stripe.paymentRequest({
  country: 'US',
  currency: 'usd',
  total: {
    label: 'Demo total',
    amount: 1099,
  },
  requestPayerName: true,
  requestPayerEmail: true,
});
const prButton = elements.create('paymentRequestButton', {
  paymentRequest,
});

(async () => {
  // Check the availability of the Payment Request API first.
  const result = await paymentRequest.canMakePayment();
  if (result) {
    prButton.mount('#payment-request-button');
  } else {
    document.getElementById('payment-request-button').style.display = 'none';
  }
})();
*/
export default {
  components: { VueRecaptcha },
  data() {
    return {
      isDisabled: false,
      email: "",
      childName:"",
      category:"4+",
      planId:"",
      plans:[],
      acceptTermsCheckBox: false,
      hasError:"",
      siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
      captcha: "",
    };
  },
  methods: {
    getPlanList() {
      PaymentService.getPlanList()
        .then((response) => {
            this.plans = response.data.data.data;
            //this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    subscribePlan() {
        this.hasError = "";
        if (this.email) {
            const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
            if (! this.email.match(validRegex)) {
                this.hasError = "Invalid Email";
                this.$toast.error("Invalid Email");
                return false;
            }
        }
        /*if (!this.email) {
            this.hasError= "Email required";
            this.$toast.error("Email required");
        } else if (! this.childName) {
            this.hasError= "childName required";
            this.$toast.error("childName required");
        } else if (! this.planId) {
            this.hasError= "Plan required";
            this.$toast.error("Plan required");
        } else {
            this.hasError= "";
        }*/
        if (this.hasError) {
            return false;
        }
        if (!this.captcha) {
            this.$toast.error("Please verify captcha.");
            return;  
        }
        let context = this;
        context.isDisabled = true;
        stripe.createToken(card).then(function (result) {
            if (result.error) {
                self.hasCardErrors = true;
                context.isDisabled = false;
                //self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
                context.$toast.error(result.error.message);
                context.hasError = result.error.message;
                return false;
            }
            let token = result.token;
            stripe.createPaymentMethod({
                type: "card",
                card: card,
                billing_details: {
                name: context.childName,
                },
            }).then(function (result) {
                let data = {
                    token: token,
                    payment_method_id: result.paymentMethod.id,
                    email: context.email,
                    childName: context.childName,
                    category: context.category,
                    planId: context.planId,
                    acceptTermsCheckBox: (context.acceptTermsCheckBox == true) ? true : "",
                    captcha: context.captcha,
                };

                PaymentService.subscribePlan(data)
                .then((response) => {
                    stripe.confirmCardPayment(response.data.data, {
                        payment_method: {
                            card: card,
                            billing_details: {
                                name: context.childName,
                            },
                        },
                    }).then(function (result) {
                        if (result.error) {
                            context.isDisabled = false;
                            context.$toast.error(result.error.message);
                            context.hasError = e.response.data.respones_msg;
                        } else {
                            context.isDisabled = false;
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                //console.log(result);
                                context.$toast.success(response.data.respones_msg);
                                card.clear();
                                return context.$router.push({ path: '/child', query: { paymentPopup: true } });
                            }
                        }
                    });
                })
              .catch((e) => {
                context.isDisabled = false;
                context.$toast.error(e.response.data.respones_msg);
                context.hasError = e.response.data.respones_msg;
              });
          });
        })
        //return this.$router.push({ path: '/child/payment', query: { email: this.email, childName: this.childName, planId: this.planId } })
    },
    verifyCaptchaMethod(recaptchaToken) {
      this.captcha = recaptchaToken;
    }
  },
  mounted: function () {
    card = elements.create('card');
    card.mount(this.$refs.card);
  },
  beforeMount() {
    this.getPlanList();
  },
}
</script>
<style type="text/css">
/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
/*stripe css end*/
</style>
<template>
  <div>
    <md-card>
      <md-card-content>
        <div class="md-layout">
            <div class="md-layout-item md-size-50">
                <h4><b>Content Name : <a href="javascript:void(0)" @click="$router.push({ path: 'question-list', query: { content_id: content_id }})">{{ content_detail.title }}</a></b></h4>
            </div>
            <div class="md-layout-item md-size-50">
                <h4><b>Total Questions : <a href="javascript:void(0)" @click="$router.push({ path: 'question-list', query: { content_id: content_id }})">{{ content_detail.total_questions }}</a></b></h4>
            </div>
        </div>
        <hr>
        <!--<div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>Page</label>
              <md-select v-model="page">
                <md-option
                  v-for="index in parseInt(total_pages)"
                  :key="index"
                  :value="index"
                  >{{ index }}</md-option
                >
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>Question Type</label>
              <md-select v-model="question_type">
                <md-option value="true-false">True / False</md-option>
                <md-option value="mcq">Multiple Choice</md-option>
                <md-option value="drawing">Drawing</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-field>
              <vue-editor style="width: 100%" v-model="question"></vue-editor>
            </md-field>
          </div>
        </div>
        
        <br>
        <hr>
        
        <div class="md-layout" v-if="question_type == 'true-false'">
          <div class="md-layout-item md-size-100">
            <h5 class="text-warning"><b>Options</b></h5>
            <md-radio v-model="correct_ans" value="True">True</md-radio>
            <md-radio v-model="correct_ans" value="False">False</md-radio>
          </div>
        </div>


        <div v-if="question_type == 'mcq'">
          <h5 class="text-warning"><b>Options</b></h5>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Option 1</label>
                <md-input type="text" v-model="option_1"></md-input>
                <md-radio
                  v-model="correct_ans"
                  :value="option_1"
                  checked="false"
                ></md-radio>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Option 2</label>
                <md-input type="text" v-model="option_2"></md-input>
                <md-radio v-model="correct_ans" :value="option_2"></md-radio>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Option 3</label>
                <md-input type="text" v-model="option_3"></md-input>
                <md-radio v-model="correct_ans" :value="option_3"></md-radio>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label>Option 4</label>
                <md-input type="text" v-model="option_4"></md-input>
                <md-radio v-model="correct_ans" :value="option_4"></md-radio>
              </md-field>
            </div>
          </div>
        </div>-->

        <!-- MCQ Questions -->
        <div v-if="question_type == 'drawing'">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :duplicateCheck="true"
                v-on:vdropzone-removed-file="RemoveFile"
                v-on:vdropzone-complete="UploadResponse"
              ></vue-dropzone>
            </div>
          </div>
        </div>
        <br>
        <br>
         <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div style="float: right">
            <md-button class="md-success" :disabled="isDisabled" @click="AddQuestion()">Add</md-button>
          </div>
          </div>
         </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
//import { VueEditor } from "vue2-editor";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import ContentDataService from "../../services/ContentDataService";

export default {
  components: {
    //VueEditor,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      isDisabled: false,
      content_id: this.$route.query.content_id,
      content_detail: null,
      total_pages: 1,
      page: "",
      question_type: "drawing",
      question: "",
      correct_ans: "",

      // MCQ
      option_1: null,
      option_2: null,
      option_3: null,
      option_4: null,

      // Drawing
      images: [],

      // DropZone
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL+"contents/upload/image",
        thumbnailWidth: 150,
        maxFilesize: 1.5,
        addRemoveLinks: true,
        headers: { Authorization: localStorage.getItem("token") },
      },
    };
  },
  methods: {
    GetContentDetail() {
       let data = {
        content_id: this.content_id,
        current_month: false,
        category: ""
      }
      ContentDataService.GetContet(data)
        .then((response) => {
            this.content_detail = response.data.data;
            this.total_pages = this.content_detail.total_pages
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    AddQuestion() {
      this.isDisabled = true;
      let options = [];
      if (this.question_type == "true-false") {
        (options[0] = "True"), (options[1] = "False");
      } else if (this.question_type == "mcq") {
        (options[0] = this.option_1),
          (options[1] = this.option_2),
          (options[2] = this.option_3),
          (options[3] = this.option_4);
      }

      let data = {
        content_id: this.content_id,
        page: this.page,
        question_type: this.question_type,
        question: this.question,
        options: options,
        correct_ans: this.correct_ans,
        images: this.images,
      };

      ContentDataService.AddQuestion(data)
        .then((response) => {
          (this.page = ""),
            (this.question_type = ""),
            (this.question = ""),
            (this.page = ""),
            (this.option_1 = ""),
            (this.option_2 = ""),
            (this.option_3 = ""),
            (this.option_4 = ""),
            (this.correct_ans = "");
          this.$toast.success(response.data.respones_msg);
          this.isDisabled = false;
          this.GetContentDetail();
          this.$router.push({path: 'question-list', query: { content_id: this.content_id }});
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
        });
      //this.$refs.myVueDropzone.removeAllFiles();
    },
    RemoveFile(file) {
      const index = this.images.indexOf(file.name);
      if (index > -1) { // only splice array when item is found
        this.images.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    UploadResponse(file) {
      this.images.push(JSON.parse(file.xhr.response).data);
      this.isDisabled = false;
    },
    beforeSending(file, xhr, formData) {
      this.isDisabled = true;
    }
  },
  beforeMount() {
    this.GetContentDetail()
  },
};
</script>

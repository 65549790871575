import http from "../child-http-common";

class ParentDataService {
    GetContet() {
        return http.get("parent-contents/list");
    }
    bookAppointment(data) {
        return http.post("parent/book/appointment", data);
    }
    getAppointments() {
        return http.get("parent/appointment/list");
    }
    appointmentsByDate(data) {
        return http.get("parent/appointment/booked/slot", {params: data});
    }
    getPaymentHistory() {
        return http.get("parent/payment/history");
    }
}

export default new ParentDataService();
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>content_paste</md-icon>
          </div>
          <h4 class="title">Content List</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Title">
                <a href="javascript:void(0)" @click="$router.push({ path: 'question-list', query: { content_id: item._id }})">{{ item.content.title }}</a></md-table-cell>
              <md-table-cell md-label="Sequence">{{ item.content.sequence_number }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: flex;">
                  <!--<md-button class="md-just-icon md-info" @click="$router.push({ path: 'view-result', query: { content_id: item.content._id, code: code, total_records: item.records.length }})"><md-icon>assignment</md-icon></md-button>-->
                  <md-button class="md-just-icon md-info" @click="$router.push({ path: 'view-result', query: { content_id: item.content._id, code: code }})"><md-icon>assignment</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <h4 v-if="tableData.length == 0">No data found.</h4>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import SubscriptionCodeService from "../../services/SubscriptionCodeService";
import Swal from "sweetalert2";

export default {
  components: {
  },
  data() {
    return {
        code: this.$route.query.code,
        tableData: [],
    };
  },
  methods: {
    GetSubmittedContents() {
        let data = {
            code: this.code      
        }
        SubscriptionCodeService.GetSubmittedContents(data)
            .then((response) => {
                this.tableData = response.data.data;
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
    this.GetSubmittedContents()
  },
};
</script>

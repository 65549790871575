<template>
  <div class="container-fluid">
    <Header />
    <md-card> 
        <md-card-content>
            <div class="mt-3">
                <div class="md-layout mobile-form-wrapper">
                    <div class="md-layout-item md-size-100" v-if="hasError">
                        <div class="error">{{ hasError }}</div>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field class="fading">
                            <md-input class="border-box" v-model="email" placeholder="Enter email" type="text"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field class="fading">
                            <md-input class="border-box" v-model="childName" placeholder="Enter child name" type="text"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <div class="option-block-container">
                            <div class="form-check option-block fading-1" v-for="plan in plans" :key="plan.id">
                                <span :style="{ 'color': '#ed1651' }" v-if="plan.interval == 'year'">Yearly</span>
                                <span :style="{ 'color': '#ed1651' }"  v-else-if="plan.interval == 'month'">Monthly</span>
                                <span :style="{ 'color': '#ed1651' }" v-else>{{ plan.interval }}</span><br>
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="planId" v-model="planId" :value="plan.id" />
                                    <span class="font-weight-bold">€{{ plan.amount / 100 }} / {{ plan.interval }}</span><br>
                                    <!--<p>{{ plan.nickname }}</p>-->
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <div ref="card"></div>
                    </div>
                    <div class="form-check md-layout-item md-size-100">
                        <br>
                        <input class="form-check-input" type="checkbox" v-model="acceptTermsCheckBox" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Accept Terms & Condition
                        </label>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <br>
                        <vue-recaptcha
                            :sitekey="siteKey"
                            @verify="verifyCaptchaMethod"
                            >
                        </vue-recaptcha>
                    </div>
                    <div class="md-layout-item md-size-100 fading">
                        <br>
                        <button class="btn btn-warning btn-lg btn-block" :disabled="isDisabled" @click="subscribePlan()">Purchase <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                    </div>
                </div>
            </div>
        </md-card-content>
    </md-card>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import PaymentService from "../../services/PaymentService";
import ChildDataService from "../../services/ChildDataService";
import { VueRecaptcha } from 'vue-recaptcha';
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
    elements = stripe.elements(),
    card = undefined;
export default {
  components: {
    Header,
    VueRecaptcha
  },
  data() {
    return {
      isDisabled: false,
      email: "",
      childName:"",
      category:"4+",
      planId:"",
      plans:[],
      acceptTermsCheckBox: false,
      hasError:"",
      siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
      captcha: "",
      subscriptionCode: null,
    };
  },
  methods: {
    getPlanList() {
      PaymentService.getPlanList()
        .then((response) => {
            this.plans = response.data.data.data;
            //this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    getSubscriptionCodeDetails() {
      ChildDataService.getSubscriptionCodeDetails()
        .then((response) => {
            this.subscriptionCode = response.data.data;
            this.childName = this.subscriptionCode.name;
            this.email = this.subscriptionCode.email;
            //this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    subscribePlan() {
        this.hasError = "";
        if (this.email) {
            const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
            if (! this.email.match(validRegex)) {
                this.hasError = "Invalid Email";
                this.$toast.error("Invalid Email");
                return false;
            }
        }
        if (this.hasError) {
            return false;
        }
        if (!this.captcha) {
            this.$toast.error("Please verify captcha.");
            return;  
        }
        let context = this;
        context.isDisabled = true;
        stripe.createToken(card).then(function (result) {
            if (result.error) {
                self.hasCardErrors = true;
                context.isDisabled = false;
                //self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
                context.$toast.error(result.error.message);
                context.hasError = result.error.message;
                return false;
            }
            let token = result.token;
            stripe.createPaymentMethod({
                type: "card",
                card: card,
                billing_details: {
                name: context.childName,
                },
            }).then(function (result) {
                let data = {
                    token: token,
                    payment_method_id: result.paymentMethod.id,
                    email: context.email,
                    childName: context.childName,
                    category: context.category,
                    planId: context.planId,
                    acceptTermsCheckBox: (context.acceptTermsCheckBox == true) ? true : "",
                    captcha: context.captcha,
                    code: context.subscriptionCode.code
                };

                PaymentService.subscribePlan(data)
                .then((response) => {
                    stripe.confirmCardPayment(response.data.data, {
                        payment_method: {
                            card: card,
                            billing_details: {
                                name: context.childName,
                            },
                        },
                    }).then(function (result) {
                        if (result.error) {
                            context.isDisabled = false;
                            context.$toast.error(result.error.message);
                            context.hasError = e.response.data.respones_msg;
                        } else {
                            context.isDisabled = false;
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                //console.log(result);
                                context.$toast.success(response.data.respones_msg);
                                card.clear();
                                document.location = "/child";
                                //return context.$router.push({ path: '/child', query: { paymentPopup: true } });
                            }
                        }
                    });
                })
              .catch((e) => {
                context.isDisabled = false;
                context.$toast.error(e.response.data.respones_msg);
                context.hasError = e.response.data.respones_msg;
              });
          });
        })
        //return this.$router.push({ path: '/child/payment', query: { email: this.email, childName: this.childName, planId: this.planId } })
    },
    verifyCaptchaMethod(recaptchaToken) {
      this.captcha = recaptchaToken;
    }
  },
  mounted: function () {
    if (!card) {
        card = elements.create("card");
        card.mount(this.$refs.card);
    } else {
        card.mount(this.$refs.card);
    }
  },
  beforeMount() {
    this.getPlanList();
    this.getSubscriptionCodeDetails();
  },
}
</script>
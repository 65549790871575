import http from "../http-common";

class ContentDataService {

    GetContet(data) {
        return http.get("contents/list", {params: data});
    }
    ChangeContentStatus(data) {
        return http.post("contents/change/status", data);
    }
    DeleteContent(data) {
        return http.post("contents/delete", data);
    }
    CreateContent(data) {
        return http.post("contents/create", data);
    }
    AddQuestion(data) {
        return http.post("contents/question/create", data);
    }
    DeleteQuestion(data) {
        return http.post("contents/question/delete", data);
    }
    GetQuestionList(content_id) {
        return http.get("contents/question/list?content_id="+content_id);
    }

    UploadAudio(data) {
        return http.post("contents/question/upload/audio", data,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }

    DeleteAudio(data) {
        return http.post("contents/question/delete/audio", data);
    }

    updatePageOrder(data) {
        return http.post("contents/question/update/order", data);
    }

}

export default new ContentDataService();
<template>
  <div class="container-fluid">
    <Header />
    <div class="colored-grid-wrapper">
      <div class="row colored-grid-rows">
        <div class="colored-grid-cols col col-md-4 mt-2">
          <a href="javascript:void(0)" class="content-grid-link" @click="$router.push({ path: '/parent/appointment'})">
            <div class="colored-grid-box" style="width: 130px; height: 190px" v-bind:style="{ 'background-color': 'rgb(215 49 79)' }">
                <p class="parent-content-number">
                  <i class="fa fa-calendar fa-5" aria-hidden="true"></i><br>
                  <span class="font-black">Appointment</span>
                </p>
              </div>
          </a>
        </div>
        <div class="colored-grid-cols col col-md-4 mt-2">
          <a href="javascript:void(0)" @click="$router.push({ path: '/parent/contents'})" class="content-grid-link">
            <div class="colored-grid-box" style="width: 130px; height: 190px" v-bind:style="{ 'background-color': 'rgb(217 221 38)' }">
                <p class="parent-content-number">
                  <i class="fa fa-clone fa-5" aria-hidden="true"></i><br>
                  <span class="font-black">Courses</span>
                </p>
              </div>
          </a>
        </div>
        <div class="colored-grid-cols col col-md-4 mt-2">
          <a href="javascript:void(0)" class="content-grid-link">
            <div class="colored-grid-box" style="width: 130px; height: 190px" v-bind:style="{ 'background-color': 'rgb(20 174 54)' }">
                <p class="parent-content-number">
                  <i class="fa fa-exclamation-triangle fa-5" aria-hidden="true"></i><br>
                  <span class="font-black">Notices</span>
                </p>
              </div>
          </a>
        </div>
        <div class="colored-grid-cols col col-md-4 mt-2">
          <a href="javascript:void(0)" @click="$router.push({ path: '/parent/payments-history'})" class="content-grid-link">
            <div class="colored-grid-box" style="width: 130px; height: 190px" v-bind:style="{ 'background-color': 'rgb(35 31 226)' }">
                <p class="parent-content-number">
                  <i class="fa fa-credit-card fa-5" aria-hidden="true"></i><br>
                  <span class="font-black">Payments</span>
                </p>
              </div>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ParentDataService from "../../services/ParentDataService";
import ChildDataService from "../../services/ChildDataService";
import Header from '@/components/Header.vue'
export default {
  components: {
    Header
  },
  props: {
    logo: {
      type: String,
      default: process.env.VUE_APP_API_BASE_URL + "images/auty-child-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      subscription_name: "",
      switchToParent: true,
    };
  },
  methods: {
    GetContet() {
      ParentDataService.GetContet()
        .then((response) => {
          this.subscription_name = response.data.subscriptionName;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.is_logout == 1) {
            localStorage.setItem("child_token", "");
            this.$router.push({ path: "/child" });
          }
        });
    },
    toggleParentMode(){
      if (! $('#cb2').is(":checked")) {
        localStorage.removeItem('parent_mode');
        this.$router.push({ path: "/child" });
      }
    },
    logout() {
      ChildDataService.logout({})
        .then((response) => {
          localStorage.setItem("child_token", "");
          document.location = "/child";
          //this.$router.push({ path: "/child" });
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetContet();
  },
  mounted: function () {
    this.$nextTick(function () {
      $('#dropDown').click(function(e){
            $('.drop-down').toggleClass('drop-down--active');
            return false;
        });
    })
  }
};
</script>
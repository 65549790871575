export default function auth({ next, router }) {
    if (!localStorage.getItem('child_token')) {
        return router.push({ name: 'child' });
    } else {
        if (!localStorage.getItem('parent_mode')) {
            if (window.location.href.indexOf('parent') > -1) {
                return document.location = "/child/contents";
            }
        } else {
            if (window.location.href.indexOf('child') > -1) {
                return document.location = "/parent";
            }
        }
    }
  
    return next();
  }
<template>
  <div v-if="this.questions">
    <div class="content">
      <div class="container-fluid mt-3">
        <div :style="{ 'text-align': 'center' }">
              <audio-player v-if="isAudio"
                ref="audioPlayer"
                :audio-list="audioList.map(elm => elm.url)"
                :show-prev-button=false
                :show-next-button=false
                :show-playback-rate=false
                :show-volume-button=false
                :isLoop=false
                theme-color="#ff2929"
              />
              <md-button v-if="isVideo" class="md-warning" @click="videoModel = true"><md-icon>switch_video</md-icon></md-button>
        </div>
        <div class="button-container action-panel">
          <md-button v-if="reivews.length > 0" class="md-success" @click="reviewModel = true"><md-icon>chat</md-icon></md-button>
        </div>
        <div class="row mt-2 justify-content-center" style="margin-left: 2%;margin-right: 2%;">
            <div class="flex-row">
              <div class="source">
                <vue-drawing-canvas 
                  ref="VueCanvasDrawing"
                  :classes="['mobile-canvas']"
                  :image.sync="image"
                  :width="width"
                  :height="height"
                  :stroke-type="strokeType"
                  :line-cap="lineCap"
                  :line-join="lineJoin"
                  :fill-shape="fillShape"
                  :eraser="eraser"
                  :lineWidth="line"
                  :color="color"
                  :background-color="backgroundColor"
                  :background-image="backgroundImage"
                  :watermark="watermark"
                  :initial-image="initialImage"
                  saveAs="png"
                  :styles="{
                    display: 'block',
                    margin: 'auto',
                  }"
                  :lock="disabled"
                  @mousemove.native="getCoordinate($event)"
                  :additional-images="additionalImages"
                />
            </div>
          <div class="col-sm-12 mt-2 sticky">
            <div class="text-center">
              <button class="btn btn-success" :disabled="current_page == 1" @click="PreviousQuestion()">
                Previous</button>&nbsp;
              <button class="btn btn-success" @click="NextQuestion()" :disabled="isDisabled">
                {{ next_btn_text }}
              </button>
              <div class="float-right">
                {{ current_page }} of {{ total_page }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Review Modal -->
    <template>
        <modal v-if="reviewModel" @close="reviewModel = false">
          <template slot="header">
            <h4 class="modal-title">Reviews</h4>
            <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="reviewModel = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
              <ul v-if="reivews.length > 0">
                <li v-for="(data, i) in reivews" :key="i">{{ data.review }}</li>
              </ul>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="reviewModel = false">Close</md-button>
          </template>
        </modal>
    </template>
    <!-- Video Modal -->
    <template>
        <modal v-if="videoModel" @close="videoModel = false" class="play-store-modal">
          <template slot="header">
            <h4 class="modal-title">Videos</h4>
            <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="videoModel = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
              <video :key="videoUrl" width="100%" controls v-if="isVideo" :style="{ padding: '5px', height: '300px' }">
                <source :src="videoUrl">
                Your browser does not support HTML video.
              </video>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="videoModel = false">Close</md-button>
          </template>
        </modal>
    </template>
  </div>
  <div v-else>No Question Found</div>
</template>
<script>
import VueDrawingCanvas from "vue-drawing-canvas";
import ChildDataService from "../../services/ChildDataService";
import AudioPlayer from '@liripeng/vue-audio-player'
import { Modal } from "@/components";

export default {
  components: {
    VueDrawingCanvas,
    AudioPlayer,
    Modal
  },
  data() {
    return {
      isDisabled: false,
      content_id: this.$route.query.content_id,
      questions: null,
      current_page: 1,
      total_page: 0,
      next_btn_text: "Next",
  
      //Audio
      isVideo: false,
      videoUrl:"",
      videoModel: false,
      isAudio: false,
      audioList: [
        {
          name: 'audio1',
          url: 'https://www.javatpoint.com/oprweb/koyal.mp3'
        }
      ],

      // Canvas
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      width:700,
      height:1000,
      x: 0,
      y: 0,
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 2,
      color: "green",
      strokeType: "dash",
      lineCap: "square",
      lineJoin: "miter",
      backgroundColor: "#FFFFFF",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      reivews: [],
      reviewModel: false,
    };
  },
  methods: {
    async GetQuestion() {
      let success = false;
      await ChildDataService.GetQuestion(this.content_id, this.current_page)
        .then((response) => {
          this.questions = response.data.data;
          this.total_page = this.questions.total_questions;
          this.reivews = response.data.data.reviews;
          if (this.questions.answer) {
            this.backgroundImage = this.questions.answer.answer;
          } else {
            this.backgroundImage = this.questions.questions.bg;
          }
          if (this.questions.questions.audio) {
            if (this.questions.questions.audioDetails) {
              if (this.questions.questions.audioDetails.mimetype.indexOf('video') > -1) {
                this.videoUrl = this.questions.questions.audio;
                this.isVideo = true;
                this.isAudio = false;
              } else {
                this.audioList[0]['url'] = this.questions.questions.audio;
                this.isAudio = true;  
                this.isVideo = false;
              }
            } else {
              this.audioList[0]['url'] = this.questions.questions.audio;
              this.isAudio = true;
              this.isVideo = false;
            }
          } else {
            this.isAudio = false;
          }
          success = true;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.is_logout == 1) {
              localStorage.setItem('child_token', "");
              this.$router.push({ path: '/parent'})
            }
            this.$toast.error(e.response.data.respones_msg);
          }
        });
      if (success == true) {
        await this.$refs.VueCanvasDrawing.reset();
      }
    },
    NextQuestion() {
        if (this.next_btn_text == "Submit") {
            return this.$router.push({ path: '/parent/contents'});
        }
        this.current_page = this.current_page + 1
        if (this.current_page == this.total_page) {
            this.next_btn_text = "Submit";
        } else {
            this.next_btn_text = "Next";
        }
        this.GetQuestion();
    },
    PreviousQuestion() {
      this.current_page = this.current_page - 1;
      if (this.current_page == this.total_page) {
        this.next_btn_text = "Submit";
      } else {
        this.next_btn_text = "Next";
      }
      this.GetQuestion();
    },

    //Canvas
    async setImage(event) {
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };
      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;
    },
    getStrokes() {
      window.localStorage.setItem(
        "vue-drawing-canvas",
        JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      );
      alert(
        "Strokes saved, reload your browser to see the canvas with previously saved image"
      );
    },
    removeSavedStrokes() {
      window.localStorage.removeItem("vue-drawing-canvas");
      alert("Strokes cleared from local storage");
    },
  },
  mounted() {
    const tabletMediaQuery = window.matchMedia('(min-width: 760px)');
    const mobileMediaQuery = window.matchMedia('(max-width: 480px)');

    if (mobileMediaQuery.matches) {
      this.width = 300;
      this.height = 500;
    }
    if (tabletMediaQuery.matches) {
      this.width = 640;
      this.height = 775;
    }
      
  },
  beforeMount() {
    this.GetQuestion();
    $(".sidebar-image").addClass("set-bg");
    $(".sidebar-image").removeClass("set-bg-blue");
    
  },
};
</script>
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>payments</md-icon>
          </div>
          <h4 class="title">Subscription Code : <b>{{ code }}</b></h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Email">{{ item.email }} <br> {{ item.stripe_customer_id }}</md-table-cell> 
              <md-table-cell md-label="Plan">
                    <span v-if="item.stripe_plan_detail">{{ item.stripe_plan_detail.amount / 100 }} / {{ item.stripe_plan_detail.interval }}</span>
                    <span v-else>-</span>
                </md-table-cell>
                <md-table-cell md-label="Expiry At">
                    <p v-if="item.expiry_at">{{ HumanReadableDateFormat(item.expiry_at, true) }}</p>
                    <p v-else>-</p>
                </md-table-cell>
                <md-table-cell md-label="Status">
                    <p v-if="item.status != 'Success'" class="badge-danger">{{ item.status }}</p>
                    <p v-else class="badge-success">{{ item.status }}</p>
                </md-table-cell> 
                <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell> 
            </md-table-row>
          </md-table>
          <h4 v-if="tableData.length == 0">No data found.</h4>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import SubscriptionCodeService from "../../services/SubscriptionCodeService";

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      isDisabled: false,
      code: this.$route.query.code,
    };
  },
  methods: {
    getPaymentHistory() {
        let data= {
            code: this.code
        }
      SubscriptionCodeService.getPaymentHistory(data)
        .then((response) => {
            this.tableData = response.data.data;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.getPaymentHistory()
  },
};
</script>

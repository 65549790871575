<template>
  <div class="container-fluid mobile-container-wrapper">
     <!--<div class="load_screen_container">
        <div class="load_screen_logo"><img src="https://portal.auntysacademy.com:3500/images/logo.png" alt="Responsive image" class="img-fluid rounded fading"></div>
    </div>-->
    
    <div class="h-50 d-flex align-items-center justify-content-center" style="padding-top: 50px;">
      <div class="text-center">
        <img
          src="https://portal.auntysacademy.com:3500/images/logo.png"
          class="img-fluid rounded fading main-logo"
          alt="Responsive image"
          
        />
      </div>
    </div>
    <div class="mt-3">
      <div class="md-layout mobile-form-wrapper">
        <div class="md-layout-item md-size-100" v-if="redirectFromPaymentPage">
          <div class="alert alert-success" role="alert">
            <h4 class="alert-heading">Payment Success</h4>
            <p>We have sent you an email with subscription code, you can login using that subscription from this page.</p>
            <hr><p>For any kind of issue please write us on auntysacademy@gmail.com</p>
          </div>
        </div>
        <div class="md-layout-item md-size-100">
          <md-field class="fading">
            <!--<label>Enter Code</label>-->
            <md-input class="border-box" placeholder="Enter code" type="text" v-model="code"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-100 fading">
          <button @click="Login" :disabled="isDisabled" class="btn btn-primary btn-lg btn-block">Login</button>
          <br>
          <p><b>Not a member? </b></p>
          <a href="child/plans"><button :disabled="isDisabled" class="btn btn-warning btn-lg btn-block">Choose a plan to start</button></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChildDataService from "../../services/ChildDataService";
export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      code: null,
      redirectFromPaymentPage: this.$route.query.paymentPopup
    };
  },
  methods: {
    Login() {
      this.isDisabled = true;
      let data = {
        code: this.code,
        userAgent: navigator.userAgent
      }
      ChildDataService.Login(data)
        .then((response) => {
            this.isDisabled = false;
            this.code = "";
            localStorage.setItem('child_token', response.data.data);
            this.$toast.success(response.data.respones_msg);
            document.location = "/child/contents";
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
  },
};
</script>

<style type="text/css">
</style>
<template>
  <div class="container-fluid">
    <!--<div class="row">
      <div class="colored-top-panel">
        <div class="colored-grid-wrapper">
          <div class="inner_header_wrp">
            <div class="inner_header_inner float-left">
                <div>
                  <img :src="logo" class="child-logo-img" style="height:50px;" />
                </div>
            </div>
            <div class="inner_header_inner">
              <div class="cm_switch_to">
                <span class="cm_switch_label">Parent Mode: </span>
                <span class="cm_switch_item">
                  <input class="cm_switch_input" v-model="switchToParent" id="cb2" type="checkbox" @click="toggleParentMode()" />
                  <label class="cm_switch_btn" for="cb2"></label>
                </span>
              </div>
              <div class="user_accoun">
                <div class="cm_drop-down_wrapper">
                  <div class="drop-down">
                    <div id="dropDown" class="drop-down__button">
                      <span class="drop-down__name">{{ subscription_name }}</span>
                      <svg
                        class="drop-down__icon"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <div class="drop-down__menu-box">
                      <ul class="drop-down__menu">
                        <li data-name="activity" class="drop-down__item">
                          <a href="javascript:void(0)" @click="logout()">Logout</a>
                          <svg
                            class="drop-down__item-icon"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12.9999 2C10.2385 2 7.99991 4.23858 7.99991 7C7.99991 7.55228 8.44762 8 8.99991 8C9.55219 8 9.99991 7.55228 9.99991 7C9.99991 5.34315 11.3431 4 12.9999 4H16.9999C18.6568 4 19.9999 5.34315 19.9999 7V17C19.9999 18.6569 18.6568 20 16.9999 20H12.9999C11.3431 20 9.99991 18.6569 9.99991 17C9.99991 16.4477 9.55219 16 8.99991 16C8.44762 16 7.99991 16.4477 7.99991 17C7.99991 19.7614 10.2385 22 12.9999 22H16.9999C19.7613 22 21.9999 19.7614 21.9999 17V7C21.9999 4.23858 19.7613 2 16.9999 2H12.9999Z"
                              fill="#000000"
                            />
                            <path
                              d="M13.9999 11C14.5522 11 14.9999 11.4477 14.9999 12C14.9999 12.5523 14.5522 13 13.9999 13V11Z"
                              fill="#000000"
                            />
                            <path
                              d="M5.71783 11C5.80685 10.8902 5.89214 10.7837 5.97282 10.682C6.21831 10.3723 6.42615 10.1004 6.57291 9.90549C6.64636 9.80795 6.70468 9.72946 6.74495 9.67492L6.79152 9.61162L6.804 9.59454L6.80842 9.58848C6.80846 9.58842 6.80892 9.58778 5.99991 9L6.80842 9.58848C7.13304 9.14167 7.0345 8.51561 6.58769 8.19098C6.14091 7.86637 5.51558 7.9654 5.19094 8.41215L5.18812 8.41602L5.17788 8.43002L5.13612 8.48679C5.09918 8.53682 5.04456 8.61033 4.97516 8.7025C4.83623 8.88702 4.63874 9.14542 4.40567 9.43937C3.93443 10.0337 3.33759 10.7481 2.7928 11.2929L2.08569 12L2.7928 12.7071C3.33759 13.2519 3.93443 13.9663 4.40567 14.5606C4.63874 14.8546 4.83623 15.113 4.97516 15.2975C5.04456 15.3897 5.09918 15.4632 5.13612 15.5132L5.17788 15.57L5.18812 15.584L5.19045 15.5872C5.51509 16.0339 6.14091 16.1336 6.58769 15.809C7.0345 15.4844 7.13355 14.859 6.80892 14.4122L5.99991 15C6.80892 14.4122 6.80897 14.4123 6.80892 14.4122L6.804 14.4055L6.79152 14.3884L6.74495 14.3251C6.70468 14.2705 6.64636 14.1921 6.57291 14.0945C6.42615 13.8996 6.21831 13.6277 5.97282 13.318C5.89214 13.2163 5.80685 13.1098 5.71783 13H13.9999V11H5.71783Z"
                              fill="#000000"
                            />
                          </svg>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <Header />
    <div style="float: right">
        <md-button class="md-warning mr-2" @click="$router.push('/parent')">Home</md-button>
    </div>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
              <md-datepicker v-model="date" md-immediately @input="appointmentsByDate()">
                <label>Select date</label>
              </md-datepicker>            
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label><b>Time</b></label>
              <md-select v-model="time" :disabled="!date">
                <md-option v-for="time in timeSlot()" :disabled="bookedSlot.indexOf(time) !== -1" :key="time" :value="time">{{ time }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div style="float: right">
            <md-button class="md-success" :disabled="isDisabled" @click="bookAppointment()">Book</md-button>
        </div>
      </md-card-content>
    </md-card>
    <div class="colored-grid-wrapper">
      <div class="row colored-grid-rows" v-if="appointments.length > 0">
        <div class="colored-grid-cols col col-md-4 mt-2" v-for="appointment in appointments" :key="appointment._id">
          <a href="javascript:void(0)" class="content-grid-link" @click="$router.push({ path: '/parent/appointment'})">
            <div class="colored-grid-box" style="width: 130px; height: 190px" v-bind:style="{ 'background-color': 'rgb(217 221 38)' }">
                <p class="parent-content-number">
                  <i class="fa fa-calendar font-black" aria-hidden="true"></i><br>
                  <span class="font-black">{{ HumanReadableDateFormat(appointment.date, true) }}</span><br>
                  <span class="font-black">{{ appointment.time}}</span><br>
                  <span v-if="appointment.status == 'Pending'" class="font-black badge-danger"><b>{{ appointment.status }}</b></span>
                  <span v-else class="font-black badge-success"><b>{{ appointment.status }}</b></span>
                </p>
              </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ParentDataService from "../../services/ParentDataService";
import ChildDataService from "../../services/ChildDataService";
import Header from '@/components/Header.vue'
export default {
  components: {
    Header
  },
  props: {
    logo: {
      type: String,
      default: process.env.VUE_APP_API_BASE_URL + "images/auty-child-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      subscription_name: "",
      switchToParent: true,
      date: null,
      time: null,
      appointments: [],
      bookedSlot: [],//['11:00 To 12:00', '13:00 To 14:00']
    };
  },
  methods: {
    GetContet() {
      ParentDataService.GetContet()
        .then((response) => {
          this.subscription_name = response.data.subscriptionName;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.is_logout == 1) {
            localStorage.setItem("child_token", "");
            this.$router.push({ path: "/child" });
          }
        });
    },
    toggleParentMode(){
      if (! $('#cb2').is(":checked")) {
        localStorage.removeItem('parent_mode');
        this.$router.push({ path: "/child" });
      }
    },
    bookAppointment() {
      this.isDisabled = true;
      let data = {
        date: this.date,
        time: this.time
      }
      
      ParentDataService.bookAppointment(data)
        .then((response) => {
          this.isDisabled = false;
          this.date = null;
          this.time = null;
          this.getAppointments();
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.is_logout == 1) {
            localStorage.setItem("child_token", "");
            this.$router.push({ path: "/child" });
          }
        });  
    },
    getAppointments() {
      ParentDataService.getAppointments()
        .then((response) => {
          this.appointments = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.is_logout == 1) {
            localStorage.setItem("child_token", "");
            this.$router.push({ path: "/child" });
          }
        });
    },
    appointmentsByDate(){
      /*let data = { date: this.date }
      ParentDataService.appointmentsByDate(data)
        .then((response) => {
          this.bookedSlot = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.is_logout == 1) {
            localStorage.setItem("child_token", "");
            this.$router.push({ path: "/child" });
          }
        });*/
    },
    logout() {
      ChildDataService.logout({})
        .then((response) => {
          localStorage.setItem("child_token", "");
          document.location = "/child";
          //this.$router.push({ path: "/child" });
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetContet();
    this.getAppointments();
  }
};
</script>
<style>
.md-list-item-content.md-ripple.md-disabled{
    background: #e5e5e5 !important;
}
</style>
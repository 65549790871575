import http from "../http-common";

class AppointmentsService {

    List(data) {
        return http.get("appointments/list", {params: data});
    }
    ChangeContentStatus(data) {
        return http.get("appointments/change/status", {params: data});
    }

}

export default new AppointmentsService();
<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
          <md-button class="md-success" @click="$router.push({ path: 'question-add', query: { content_id: content_id }})">Add Question</md-button>
      </div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>help</md-icon>
          </div>
          <h4 class="title">Question List</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
                <h4><b>Content Name : <a href="javascript:void(0)" @click="$router.push({ path: 'question-list', query: { content_id: content_id }})">{{ content_detail.title }}</a></b></h4>
            </div>
            <div class="md-layout-item md-size-50">
                <h4><b>Total Questions : <a href="javascript:void(0)" @click="$router.push({ path: 'question-list', query: { content_id: content_id }})">{{ content_detail.total_questions }}</a></b></h4>
            </div>
          </div>
          <hr>
          <md-table :value="queriedData" table-header-color="green" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <!--<md-table-cell md-label="Question" v-html="item.question">{{ item.question }}</md-table-cell>
              <md-table-cell md-label="Options">
                <ol>
                  <li v-for="option in item.options" :key="option">{{ option }}</li>
                </ol>
              </md-table-cell>
              <md-table-cell md-label="Correct Ans">{{ item.correct_ans }}</md-table-cell>
              <md-table-cell md-label="Type">{{ item.question_type }}</md-table-cell>-->
              <md-table-cell md-label="Question">
                  <img :src="GetImageUrl(item.image)" style="width:80px;" />
              </md-table-cell>
              <md-table-cell md-label="Audio">
                <div class="md-layout-item md-size-100" v-if="!item.audio">
                    <br>
                    <input type="file" @change="UploadAudio( $event, item._id )" accept="video/*,audio/*" />
                </div>
                <span v-else>
                  {{ item.audio }}&nbsp; <a href="javascript:void(0)" class="md-just-icon md-danger md-simple" @click="DeleteAudio(item._id, item.audio)"><md-icon class="icon-simple">close</md-icon></a>
                </span>
              </md-table-cell>
              <md-table-cell md-label="Actions">
                  <div>
                    <md-button class="md-just-icon md-danger md-simple" @click="DeleteQuestion(item._id)"><md-icon>close</md-icon></md-button>
                    <md-button v-if="item.page != 1" class="md-just-icon md-primary md-simple" @click="updatePageOrder(item._id, 'up')"><md-icon>arrow_upward</md-icon></md-button>
                    <md-button v-if="tableData.length != item.page" class="md-just-icon md-info md-simple" @click="updatePageOrder(item._id, 'down')"><md-icon>arrow_downward</md-icon></md-button>
                  </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import ContentDataService from "../../services/ContentDataService";
import Swal from "sweetalert2";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      content_id: this.$route.query.content_id,
      tableData: [],
      content_detail: null,
      audioFile: null,

      // Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      searchedData: [],
    };
  },
  computed: {
    // Pagination
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  methods: {
    GetContentDetail() {
      let data = {
        content_id: this.content_id,
        current_month: false,
        category: ""
      }
      ContentDataService.GetContet(data)
        .then((response) => {
            this.content_detail = response.data.data;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    GetQuestionList() {
      ContentDataService.GetQuestionList(this.$route.query.content_id)
        .then((response) => {
            this.tableData = response.data.data;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    DeleteQuestion(question_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `You won't recover this data`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let data = {
            question_id : question_id
          };
          ContentDataService.DeleteQuestion(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetQuestionList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        }
      });
    },

    UploadAudio(event, id){
      this.audioFile = event.target.files[0];
      /*if (this.audioFile.type != "audio/mpeg") {
          this.$toast.error("Please select mp3 audio file only");
          return false;
      }*/
      let formData = new FormData();
      formData.append("file", this.audioFile);
      formData.append("question_id", id);
      ContentDataService.UploadAudio(formData)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetQuestionList();
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    DeleteAudio(question_id, file) {
      Swal.fire({
        title: "Are you sure?",
        text: `You won't recover this data`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let data = {
            question_id : question_id,
            file: file
          };
          ContentDataService.DeleteAudio(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetQuestionList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        }
      });
    },

    GetImageUrl: function(value) {
      return process.env.VUE_APP_API_BASE_URL + "upload/assignments/" + value;
    },

    updatePageOrder(id, type) {
      let data = {
        id: id,
        type: type
      }
      ContentDataService.updatePageOrder(data)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetQuestionList();
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.GetContentDetail();
    this.GetQuestionList();
  },
};
</script>

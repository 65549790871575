<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
          <md-button class="md-success" @click="$router.push({ path: 'create-content'})">Create Content</md-button>
      </div>
      <md-card>
          <md-card-content>
              <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                      <md-field>
                        <label>Category</label>
                        <md-select placeholder="Select Category" v-model="category">
                            <md-option v-for="category in GetCategory()" :key="category" :value="category">{{ category }}</md-option>
                        </md-select>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-20">
                      <md-button class="md-primary" :disabled="isDisabled" @click="GetContet()">Search</md-button> 
                  </div>
              </div>
          </md-card-content>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>content_paste</md-icon>
          </div>
          <h4 class="title">Content List</h4>
        </md-card-header>
        <md-card-content v-if="tableData.length == 0">
          <h4 >No data found.</h4>
        </md-card-content>
        <md-card-content v-else>
          <md-table :value="queriedData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort"
              class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
            
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
           </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Title" md-sort-by="title">
                <a href="javascript:void(0)" @click="$router.push({ path: 'question-list', query: { content_id: item._id }})">{{ item.title }}</a></md-table-cell>
              <md-table-cell md-label="Total Questions">{{ item.total_questions  }}</md-table-cell>
              <md-table-cell md-label="Category" md-sort-by="category">{{ item.category }}</md-table-cell>
              <md-table-cell md-label="Sequence" md-sort-by="sequence_number">{{ item.sequence_number }}</md-table-cell>
              <md-table-cell md-label="Status">
                <a href="javascript:void(0)" v-if="item.status != 'Unpublish'" @click="ChangeContentStatus(item._id)"><p class="badge-success">{{ item.status }}</p></a>
                <a href="javascript:void(0)" v-else @click="ChangeContentStatus(item._id)"><p class="badge-danger">{{ item.status }}</p></a>
              </md-table-cell>
              <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                  <md-button class="md-just-icon md-danger md-simple" @click="DeleteContent(item._id)"><md-icon>close</md-icon></md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </pagination>
          </md-card-actions>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import ContentDataService from "../../services/ContentDataService";
import Swal from "sweetalert2";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      category: "",

      //Paginations
      searchedData: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {
    
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder == "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    GetContet() {
      let data = {
        content_id: "",
        current_month: false,
        category: this.category
      }
      ContentDataService.GetContet(data)
        .then((response) => {
            this.tableData = response.data.data;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    ChangeContentStatus(content_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `Status will be changed for this content`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, change it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let data = {
            content_id : content_id
          };
          ContentDataService.ChangeContentStatus(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetContet();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        }
      });
    },
    DeleteContent(content_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `Content & It's questions deleted.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, Delete It!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let data = {
            content_id : content_id
          };
          ContentDataService.DeleteContent(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetContet();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        }
      });
    }
  },
  beforeMount() {
    this.GetContet()
  },
};
</script>

import http from "../child-http-common";

class PaymentService {
    getPlanList(data = null) {
        return http.get("payment/plan/details", {params: data});
    }
    subscribePlan(data) {
        return http.post("payment/subscribePlan", data);
    }
}

export default new PaymentService();
<template>
  <div v-if="this.questions">
    <div class="content">
      <p class="float-left pr-3 h4"> <a href="javascript:void(0)" class="md-just-icon md-success md-simple" @click="$router.push({ path: '/child'})"><img :src="logo" alt="Avatar" style="width:75px;"> </a></p> 
      <p class="float-right pr-3 h4">  <b>{{ childName }}</b></p> 
      <div class="container-fluid mt-3">
        <div :style="{ 'text-align': 'center' }">
              <audio-player v-if="isAudio"
                ref="audioPlayer"
                :audio-list="audioList.map(elm => elm.url)"
                :show-prev-button=false
                :show-next-button=false
                :show-playback-rate=false
                :show-volume-button=false
                :isLoop=false
                theme-color="#ff2929"
              />
              <md-button v-if="isVideo" class="md-warning" @click="videoModel = true"><md-icon>switch_video</md-icon></md-button>
        </div>
        <div :style="{ 'text-align': 'center' }" v-if="questionLoader">
          <div class="loader" :style="{ margin: 'auto' }"></div>
        </div>
        <div class="button-container action-panel" v-if="!questionLoader">
          <button type="button" @click.prevent="$refs.VueCanvasDrawing.undo()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
              <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
            </svg>
            Undo
          </button>
          <button type="button" @click.prevent="eraser = !eraser" @click="handelEraser()" :class="eraser ? 'btn btn-success' : ''">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
                <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
              </svg>
              Eraser
            </span>
          </button>
          <button type="button" @click.prevent="eraser = !eraser" @click="handelPen()" :class="!eraser ? 'btn btn-success' : ''">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
              </svg>
              Draw
            </span>
          </button>
          <!--<button type="button" @click.prevent="eraser = !eraser">
            <span v-if="eraser">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
                <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
              </svg>
              Eraser
            </span>
            <span v-else>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
              </svg>
              Draw
            </span>
          </button>-->
          <input type="color" v-model="color" />
          <md-button v-if="reivews.length > 0" class="md-success" @click="reviewModel = true"><md-icon>chat</md-icon></md-button>
        </div>
        <div class="row mt-2 justify-content-center" style="margin-left: 2%;margin-right: 2%;" v-if="!questionLoader">
            <div class="flex-row">
              <div class="source">
                <vue-drawing-canvas 
                  ref="VueCanvasDrawing"
                  :classes="['mobile-canvas']"
                  :image.sync="image"
                  :width="width"
                  :height="height"
                  :stroke-type="strokeType"
                  :line-cap="lineCap"
                  :line-join="lineJoin"
                  :fill-shape="fillShape"
                  :eraser="eraser"
                  :lineWidth="line"
                  :color="color"
                  :background-color="backgroundColor"
                  :background-image="backgroundImage"
                  :watermark="watermark"
                  :initial-image="initialImage"
                  saveAs="png"
                  :styles="{
                    display: 'block',
                    margin: 'auto',
                  }"
                  :lock="disabled"
                  @mousemove.native="getCoordinate($event)"
                  :additional-images="additionalImages"
                />
                <br>
                <!--<div class="button-container">
                  <button type="button" @click.prevent="disabled = !disabled">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
                      <path v-if="!disabled" d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
                      <path v-else d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                    </svg>
                    <span v-if="!disabled">Unlock</span>
                    <span v-else>Lock</span>
                  </button>
                  <button type="button" @click.prevent="$refs.VueCanvasDrawing.undo()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                    </svg>
                    Undo
                  </button>
                  <button type="button" @click.prevent="$refs.VueCanvasDrawing.redo()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    Redo
                  </button>
                  <button type="button" @click.prevent="$refs.VueCanvasDrawing.reset()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    </svg>
                    Reset
                  </button>
                </div>-->
                <div class="button-container">
                  <!--<button type="button" @click.prevent="eraser = !eraser">
                    <span v-if="eraser">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
                        <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                      </svg>
                      Eraser
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                      </svg>
                      Draw
                    </span>
                  </button>
                  <input type="color" v-model="color" />-->
                  <!--<select v-model="line">
                    <option v-for="n in 25" :key="'option-' + n" :value="n">
                      {{ n }}
                    </option>
                  </select>-->
                  <!--<select v-model="strokeType">
                    <option value="dash">Dash</option>
                    <option value="line">Straight Line</option>
                    <option value="circle">Circle</option>
                    <option value="square">Square</option>
                    <option value="triangle">Triangle</option>
                    <option value="half_triangle">Half Triangle</option>
                  </select>
                  <select v-model="lineCap">
                    <option value="round">lineCap Round</option>
                    <option value="square">lineCap Square</option>
                    <option value="butt">lineCap butt</option>
                  </select>
                  <select v-model="lineJoin">
                    <option value="round">lineJoin Round</option>
                    <option value="miter">lineJoin miter</option>
                    <option value="bevel">lineJoin bevel</option>
                  </select>-->
                </div>
                <!--<div class="button-container">
                  <div style="margin-right: 30px">
                    <p style="margin-bottom: 0">Background Color:</p>
                    <input type="color" v-model="backgroundColor" />
                  </div>
                  <div>
                    <p style="margin-bottom: 0">Upload Background Image:</p>
                    <input type="file" @change="setImage($event)" />
                  </div>
                  <div>
                    <p style="margin-bottom: 0">Upload Watermark Image:</p>
                    <input type="file" @change="setWatermarkImage($event)" />
                  </div>
                </div>-->
              </div>

              <!--<div class="output">
                <p>Output:</p>
                <img :src="image" style="border: solid 1px #000000" />
              </div>-->
            </div>
          <div class="col-sm-12 mt-1 sticky">
            <div class="text-center">
              <button class="btn btn-primary" @click="$router.push({ path: '/child'})"><md-icon>home</md-icon></button>&nbsp;
              <button class="md-just-icon btn btn-success md-simple" @click="goToFirstPage()">
                <md-icon>first_page</md-icon></button>&nbsp;
              <button class="md-just-icon btn btn-success md-simple" :disabled="current_page == 1" @click="PreviousQuestion()">
                <md-icon>arrow_back</md-icon></button>&nbsp;
              <button class="btn btn-success" @click="NextQuestion()" :disabled="isDisabled">
                <md-icon>arrow_forward</md-icon><!--{{ next_btn_text }}-->
              </button>
              <div class="float-right pt-2">
                {{ current_page }} of {{ total_page }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Review Modal -->
    <template>
        <modal v-if="reviewModel" @close="reviewModel = false">
          <template slot="header">
            <h4 class="modal-title">Reviews</h4>
            <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="reviewModel = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
              <ul v-if="reivews.length > 0">
                <li v-for="(data, i) in reivews" :key="i">{{ data.review }}</li>
              </ul>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="reviewModel = false">Close</md-button>
          </template>
        </modal>
    </template>
    <!-- Video Modal -->
    <template>
        <modal v-if="videoModel" @close="videoModel = false" class="play-store-modal">
          <template slot="header">
            <h4 class="modal-title">Videos</h4>
            <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="videoModel = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
              <video :key="videoUrl" width="100%" controls v-if="isVideo" :style="{ padding: '5px', height: '300px' }">
                <source :src="videoUrl">
                Your browser does not support HTML video.
              </video>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="videoModel = false">Close</md-button>
          </template>
        </modal>
    </template>
  </div>
  <div v-else>No Question Found</div>
</template>
<script>
import VueDrawingCanvas from "vue-drawing-canvas";
import ChildDataService from "../../services/ChildDataService";
import AudioPlayer from '@liripeng/vue-audio-player';
import { Modal } from "@/components";

export default {
  components: {
    VueDrawingCanvas,
    AudioPlayer,
    Modal
  },
  props: {
    logo: {
      type: String,
      default: process.env.VUE_APP_API_BASE_URL + "images/auty-child-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      content_id: this.$route.query.content_id,
      questions: null,
      current_page: 1,
      total_page: 0,
      next_btn_text: "Next",
      childName: "",
      questionLoader: false,
  
      //Audio
      isVideo: false,
      videoUrl:"",
      videoModel: false,
      isAudio: false,
      audioList: [
        {
          name: 'audio1',
          url: 'https://www.javatpoint.com/oprweb/koyal.mp3'
        }
      ],

      // Canvas
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      width:700,
      height:1000,
      x: 0,
      y: 0,
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 2,
      color: "green",
      strokeType: "dash",
      lineCap: "square",
      lineJoin: "miter",
      backgroundColor: "#FFFFFF",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      reivews: [],
      reviewModel: false,
    };
  },
  methods: {
    async GetQuestion() {
      let success = false;
      this.questionLoader = true;
      await ChildDataService.GetQuestion(this.content_id, this.current_page)
        .then((response) => {
          this.questionLoader = false;
          this.childName = response.data.subscriptionName;
          this.questions = response.data.data;
          /*if (this.questions.answer) {
            this.initialImage = this.questions.answer.stroke;
          } else {
            this.initialImage = null;
          }*/
          this.total_page = this.questions.total_questions;
          this.reivews = response.data.data.reviews;
          if (this.questions.answer) {
            this.backgroundImage = this.questions.answer.answer;
          } else {
            this.backgroundImage = this.questions.questions.bg;
          }

          if (this.questions.questions.audio) {
            if (this.questions.questions.audioDetails) {
              if (this.questions.questions.audioDetails.mimetype.indexOf('video') > -1) {
                this.videoUrl = this.questions.questions.audio;
                this.isVideo = true;
                this.isAudio = false;
              } else {
                this.audioList[0]['url'] = this.questions.questions.audio;
                this.isAudio = true;  
                this.isVideo = false;
              }
            } else {
              this.audioList[0]['url'] = this.questions.questions.audio;
              this.isAudio = true;
              this.isVideo = false;
            }
          } else {
            this.isAudio = false;
            this.isVideo = false;
          }
          success = true;
        })
        .catch((e) => {
          this.questionLoader = false;
          if (e.response) {
            if (e.response.data.is_expired == 1) {
              localStorage.setItem('parent_mode', true);
              document.location = "/parent/make-payment";
            }
            if (e.response.data.is_logout == 1) {
              localStorage.setItem('child_token', "");
              this.$router.push({ path: '/child'})
            }
            this.$toast.error(e.response.data.respones_msg);
          }
        });
      if (success == true) {
        await this.$refs.VueCanvasDrawing.reset();
      }
    },
    NextQuestion() {
      this.isDisabled = true;
      let answer = this.backgroundImage;
      if (this.$refs.VueCanvasDrawing.isEmpty() == false) {
        answer = this.image;
      }
      let data = {
          content_id: this.content_id,
          question_id: this.questions.questions._id,
          question: this.questions.questions.image,
          answer: answer,
          stroke: this.$refs.VueCanvasDrawing.getAllStrokes(),
          is_submit: (this.next_btn_text == "Submit") ? 1 : 0
      }
      ChildDataService.SaveAnswer(data)
        .then((response) => {
          this.isDisabled = false;
          if (this.next_btn_text == "Submit") {
            return this.$router.push({ path: '/child/contents', query: { contentSubmitted: true }});
          }
          this.current_page = this.current_page + 1;
          if (this.current_page == this.total_page) {
            this.next_btn_text = "Submit";
          } else {
            localStorage.setItem(this.content_id, this.current_page);
            this.next_btn_text = "Next";
          }
          this.GetQuestion();
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    PreviousQuestion() {
      this.current_page = this.current_page - 1;
      if (this.current_page == this.total_page) {
        this.next_btn_text = "Submit";
      } else {
        this.next_btn_text = "Next";
      }
      this.GetQuestion();
    },

    goToFirstPage() {
      this.current_page = 1
      this.GetQuestion();
    }, 
    //Canvas
    async setImage(event) {
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };
      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;
    },
    getStrokes() {
      window.localStorage.setItem(
        "vue-drawing-canvas",
        JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      );
      alert(
        "Strokes saved, reload your browser to see the canvas with previously saved image"
      );
    },
    removeSavedStrokes() {
      window.localStorage.removeItem("vue-drawing-canvas");
      alert("Strokes cleared from local storage");
    },
    handelEraser() {
      this.line = 18;
    },
    handelPen() {
      this.line = 2;
    }
  },
  mounted() {
    /*if ("vue-drawing-canvas" in window.localStorage) {
      this.initialImage = JSON.parse(
        window.localStorage.getItem("vue-drawing-canvas")
      );
    }*/
    const tabletMediaQuery = window.matchMedia('(min-width: 760px)');
    const mobileMediaQuery = window.matchMedia('(max-width: 480px)');
    // Check if the media query is true
    /*if (mediaQuery.matches) {
      this.width = 1000;
    }*/
    if (mobileMediaQuery.matches) {
      this.width = 300;
      this.height = 500;
    }
    if (tabletMediaQuery.matches) {
      this.width = 640;
      this.height = 770;
    }
      
  },
  beforeMount() {
    if (localStorage.getItem(this.content_id)) {
      this.current_page = parseInt(localStorage.getItem(this.content_id));
    }
    this.GetQuestion();
    $(".sidebar-image").addClass("set-bg");
    $(".sidebar-image").removeClass("set-bg-blue");
    
  },
};
</script>
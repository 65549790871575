<template>
  <div class="container-fluid mobile-container-wrapper">
    <div class="mt-3">
      <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <dl class="row">
              <dt class="col-sm-6">Email: </dt>
              <dd class="col-sm-6">{{ email }}</dd>
              <dt class="col-sm-6">Plan Price</dt>
              <dd class="col-sm-6 text-warning font-weight-bold">€{{ planDetail.unit_amount / 100 }} /
                <span v-if="planDetail && planDetail.recurring.interval == 'year'">Yearly</span>
                <span v-else-if="planDetail && planDetail.recurring.interval == 'month'">Monthly</span>
              </dd>
            </dl>
          </div>
          <div class="md-layout-item md-size-100">
            <div ref="card"></div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentService from "../../services/PaymentService";
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
    elements = stripe.elements(),
    card = undefined;

export default {
  data() {
    return {
      isDisabled: false,
      email: this.$route.query.email,
      childName:this.$route.query.childName,
      planId:this.$route.query.planId,
      planDetail: "",
    };
  },
  methods: {
    getPlanList() {
        let data = { planId: this.planId };    
        PaymentService.getPlanList(data)
        .then((response) => {
            this.planDetail = response.data.data;
            //this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  mounted: function () {
    card = elements.create('card');
    card.mount(this.$refs.card);
  },
  beforeMount() {
    this.getPlanList();
  },
};
</script>
<style type="text/css">
/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
/*stripe css end*/
</style>
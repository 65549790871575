import http from "../http-common";

class ContentDataService {

    GetSubscriptionCode(data) {
        return http.get("subscription-code/list", {params: data});
    }
    CreateSubscriptionCode(data) {
        return http.post("subscription-code/create", data);
    }
    GetSubmittedContents(data) {
        return http.get("subscription-code/content/list", { params: data });
    }
    GetResult(data) {
        return http.get("subscription-code/content/result", { params: data });
    }
    downloadResult(data) {
        return http.get("subscription-code/content/result/pdf", { params: data });
    }
    GetLoginHistory(data) {
        return http.get("subscription-code/login/history", { params: data });
    }
    UpdateLogoutFlag(data) {
        return http.post("subscription-code/logout", data);
    }
    submitReview(data) {
        return http.post("contents/answer/review", data);
    }
    getAnswerReview(data) {
        return http.get("contents/answer/review", { params: data });
    }
    getPaymentHistory(data) {
        return http.get("subscription-code/payment/history", { params: data });
    }

}

export default new ContentDataService();
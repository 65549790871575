<template>
  <div class="md-layout">
    Welcome To Dashboard
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
    //this.GetDashboardData();
  },
};
</script>
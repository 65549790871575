<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-primary" :disabled="isDisabled" @click="downloadResult()">Export</md-button>
      </div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>content_paste</md-icon>
          </div>
          <h4 class="title">Result</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <div class="row mt-2 justify-content-center">
                <img :src="image" style="width: 50%;" /> 
                  <div class="col-sm-12 mt-2 sticky">
                    <div class="text-center">
                      <button class="btn btn-success" :disabled="page == 0" @click="PreviousResult()">Previous</button>&nbsp;
                      <button class="btn btn-success" :disabled="(page + 1 ) == total_records" @click="NextResult()">Next</button>
                    </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-50">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-field>
                      <label>Enter Comments</label>
                      <md-input type="text" v-model="review"></md-input>
                    </md-field>
                  </div>
                  <div style="float: right">
                    <md-button class="md-success" :disabled="isDisabled" @click="submitReview()">Save</md-button>
                  </div>
                </div>
                <ul v-if="reviews.length > 0">
                  <li v-for="(data, i) in reviews" :key="i">{{ data.review }}</li>
                </ul>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import SubscriptionCodeService from "../../services/SubscriptionCodeService";
import ContentDataService from "../../services/ContentDataService";

export default {
  components: {
  },
  data() {
    return {
        isDisabled: false,
        code: this.$route.query.code,
        page: 0,
        content_id: this.$route.query.content_id,
        total_records: this.$route.query.total_records,
        image: "",
        review: "",
        answer_id:"",
        reviews: [],
    };
  },
  methods: {
    GetResult() {
        let data = {
            code: this.code,
            page: this.page,
            content_id: this.content_id  
        }
        SubscriptionCodeService.GetResult(data)
            .then((response) => {
                this.image = response.data.data.answer;
                this.answer_id = response.data.data._id;
                this.getAnswerReview(this.answer_id);
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
    },
    downloadResult() {
        this.isDisabled = true;
        let data = {
            code: this.code,
            content_id: this.content_id  
        }
        SubscriptionCodeService.downloadResult(data)
            .then((response) => {
                if (response.data.data) {
                  this.isDisabled = false;
                  let a= document.createElement('a');
                  a.target= '_blank';
                  a.href= response.data.data;
                  a.click();
                }
               /* window.open(
                  response.data.data,
                  '_blank'
                );*/
            }).catch((e) => {
                sthis.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
    },
    PreviousResult() {
        this.page = this.page - 1;
        this.GetResult();
    },
    NextResult() {
        this.page = this.page + 1;
        this.GetResult();
    },
    GetContentDetail() {
       let data = {
        content_id: this.content_id,
        current_month: false,
        category: ""
      }
      ContentDataService.GetContet(data)
        .then((response) => {
            this.total_records = response.data.data.total_questions;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },

    getAnswerReview(answer_id) {
      let data = {
            answer_id: answer_id,
        }
        SubscriptionCodeService.getAnswerReview(data)
            .then((response) => {
                this.reviews = response.data.data;
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
    },
    
    submitReview() {
        let data = {
            answer_id: this.answer_id,
            review: this.review,
        }
        SubscriptionCodeService.submitReview(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.review = "";
                this.getAnswerReview(this.answer_id);
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
    this.GetContentDetail();
    this.GetResult();
  },
};
</script>

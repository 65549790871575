import http from "../child-http-common";

class ChildDataService {

    GetContet(content_id = "", current_month = false) {
        return http.get("contents/list?content_id="+content_id+"&current_month="+current_month);
    }
    GetQuestion(content_id = "", page = 1) {
        return http.get("contents/detail?content_id="+content_id+"&page="+page);
    }
    Login(data) {
        return http.post("contents/login", data);
    }
    SaveAnswer(data) {
        return http.post("contents/answer", data);
    }
    logout(data) {
        return http.post("contents/logout", data);
    }
    getSubscriptionCodeDetails() {
        return http.get("contents/subscription-code/details");
    }

}

export default new ChildDataService();
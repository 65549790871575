<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
          <md-button class="md-success" @click="createSubscriptionCodeModel = true">Create Subscription Code</md-button>
      </div>
      <md-card>
          <md-card-content>
              <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                      <md-field>
                        <label>Category</label>
                        <md-select placeholder="Select Category" v-model="search_category">
                            <md-option v-for="category in GetCategory()" :key="category" :value="category">{{ category }}</md-option>
                        </md-select>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-20">
                      <md-button class="md-primary" :disabled="isDisabled" @click="GetSubscriptionCode()">Search</md-button> 
                  </div>
              </div>
          </md-card-content>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>loyalty</md-icon>
          </div>
          <h4 class="title">Subscription Code List</h4>
        </md-card-header>
        <md-card-content v-if="tableData.length == 0">
          <h4 >No data found.</h4>
        </md-card-content>
        <md-card-content v-else>
          <md-table :value="queriedData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort"
              class="paginated-table table-striped table-hover" 
              table-header-color="green" md-fixed-header>

            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
           </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Code">
                <a href="javascript:void(0)" @click="$router.push({ path: 'submit-content-list', query: { code: item.code }})">  {{ item.code  }}</a>
              </md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
              <md-table-cell md-label="Status">
                  <p v-if="item.type == 'Trial'" class="badge-danger">{{ item.type }}</p>
                  <p v-else-if="item.type == 'Paid'" class="badge-success">{{ item.type }}</p>
                  <p v-else class="badge-warning">{{ item.type }}</p>
              </md-table-cell> 
              <md-table-cell md-label="Expiry At" md-sort-by="expiry_at">
                <span v-if="item.expiry_at">{{ HumanReadableDateFormat(item.expiry_at, true) }}</span>
                <span v-else>-</span>
              </md-table-cell>
              <md-table-cell md-label="Category" md-sort-by="category">{{ item.category }}</md-table-cell>
              <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div>
                  <md-button class="md-just-icon md-success md-simple" @click="$router.push({ path: 'login-history', query: { code: item.code }})"><md-icon>login</md-icon></md-button>
                  <md-button class="md-just-icon md-primary md-simple" @click="$router.push({ path: 'payment-history', query: { code: item.code }})"><md-icon>payments</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </pagination>
          </md-card-actions>
        </md-card-content>
      </md-card>
      <!-- Create Subscription Code Modal -->
      <template>
          <modal v-if="createSubscriptionCodeModel" @close="createSubscriptionCodeModel = false">
            <template slot="header">
              <h4 class="modal-title">Create Subscription Code</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="createSubscriptionCodeModel = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <select class="html-select-box" placeholder="Select Category" v-model="category">
                        <option v-for="category in GetCategory()" :key="category" :value="category">{{ category }}</option>
                    </select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <label>Child Name</label>
                    <md-input type="text" v-model="child_name"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <select class="html-select-box" placeholder="Select Category" v-model="type">
                        <option value="">Select Type</option>
                        <option value="Test">Test</option>
                        <option value="Trial">Trial</option>
                    </select>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="createSubscriptionCodeModel = false">Close</md-button>
              <md-button class="md-success" :disabled="isDisabled" @click="CreateSubscriptionCode()">Save</md-button>
            </template>
          </modal>
      </template>
    </div>
  </div>
</template>
<script>
import SubscriptionCodeService from "../../services/SubscriptionCodeService";
import Swal from "sweetalert2";
import { Modal } from "@/components";
import { Pagination } from "@/components";

export default {
  components: {
    Modal,
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      tableData: [],
      isDisabled: false,

      // Create Subscription Code
      createSubscriptionCodeModel: false,
      child_name: null,
      category: "4+",
      search_category: "",
      type:"",

      //Paginations
      searchedData: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder == "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    GetSubscriptionCode() {
      let data = {
        category: this.search_category
      }
      SubscriptionCodeService.GetSubscriptionCode(data)
        .then((response) => {
            this.tableData = response.data.data;
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    CreateSubscriptionCode() {
      this.isDisabled = true;
      let data = {
        name: this.child_name,
        category: this.category,
        type: this.type
      }
      SubscriptionCodeService.CreateSubscriptionCode(data)
        .then((response) => {
            this.isDisabled = false;
            this.child_name = "";
            this.createSubscriptionCodeModel = false;
            this.GetSubscriptionCode();
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetSubscriptionCode()
  },
};
</script>

import Vue from "vue";

Vue.mixin({
    methods: {
        capitalizeFirstLetter(str) {
            return str.toUpperCase();
        },
        HumanReadableDateFormat(date, onlyDate = false) {
            const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
            let dt = new Date(date);
            let day = ("0" + dt.getDate()).slice(-2);
            let month = dt.getMonth();
            let year = dt.getFullYear();
            let hour = ("0" + dt.getHours()).slice(-2);
            let minute = ("0" + dt.getMinutes()).slice(-2);
            let seconds = ("0" + dt.getSeconds()).slice(-2);
            if (onlyDate) {
                return day + " " + monthNames[month] + " " + year;
            } else {
                return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
            }
        },
        ReplaceString(str, split,join) {
            if (str) {
                return str.split(split).join(join);
            } else {
                return str;
            }
        },
        WhiteLabellingTitle() {
            return "PrepApp";
        },
        GetServerBaseUrl() {
            return process.env.VUE_APP_API_BASE_URL
        },
        
        GetCategory() {
            return ['4+', '7+', 'Test 4+', 'Test 7+'];
        },

        timeSlot() {
            return ['10:00 To 11:00', '11:00 To 12:00', '12:00 To 13:00', '13:00 To 14:00'
            , '14:00 To 15:00', '15:00 To 16:00', '16:00 To 17:00', '17:00 To 18:00', '18:00 To 19:00'];
        }
    }
})
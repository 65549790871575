<template>
  <div>
    <div style="float: right">
      <md-button class="md-success" :disabled="isDisabled" @click="CreateContent()">Save</md-button>
    </div>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>Title</label>
              <md-input type="text" v-model="title"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>Category</label>
              <md-select placeholder="Select Category" v-model="category">
                  <md-option v-for="category in GetCategory()" :key="category" :value="category">{{ category }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>Sequence Number</label>
              <md-input type="number" v-model="sequence_number" min="0" max="48" maxlength="2"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-33">
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              @tags-changed="newTags => tags = newTags"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import ContentDataService from "../../services/ContentDataService";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  components: {
    VueTagsInput,
  },
  data() {
    return {
      isDisabled: false,
      title: null,
      category: "4+",
      sequence_number: null,
      content_id: "",
      tag: '',
      tags: [],
    };
  },
  methods: {
    CreateContent() {
      this.isDisabled = true;
      let data = {
        title: this.title,
        category: this.category,
        tags: this.tags,
        sequence_number: parseInt(this.sequence_number),
      }
      ContentDataService.CreateContent(data)
        .then((response) => {
            this.isDisabled = false;
            this.content_id = response.data.data._id;
            this.$toast.success(response.data.respones_msg);
            this.$router.push({name: 'Question Add', query: { content_id: this.content_id }});
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
  },
};
</script>
<style>
@import '../../assets/css/common.css';
</style>
